import { Duration } from '@js-joda/core';
import { PeriodType } from '@thinkalpha/language-services';
import { getContext, call } from 'redux-saga/effects';
import { getWorkspaceConfigQuery } from 'src/api/queries';
import type {
    AccountsWidgetModel,
    AggregatedPositionsWidgetModel,
    ExecutionsWidgetModel,
    IfThenBuilderWidgetModel,
    SearchAlphaWidgetModel,
    AlphaLensWidgetModel,
    LocatesWidgetModel,
    MessagesWidgetModel,
    OrderBlotterWidgetModel,
    OrderEntryWidgetModel,
    OrdersWidgetModel,
    PositionsWidgetModel,
    StrategyWidgetModel,
    TableWidgetModel,
    TimeSeriesWidgetModel,
    WidgetCreationInfo,
    WorkspaceConfig,
    AlphaLensContent,
    SimpleOrderEntryWidgetModel,
    NewsWidgetModel,
} from 'src/contracts/workspace';
import { getWidgetDisplayName } from 'src/features/widget/model/widget';
import { getAlphaLensDefaultWidget } from 'src/lib/user-preference';
import { defaultQuantityStep } from 'src/routes/widgets/LocatesWidget/formatting';
import {
    PRIMARY_SERIES_ID,
    VOLUME_SERIES_ID,
    timeSeriesAxisNames,
    timeSeriesPaneNames,
} from 'src/routes/widgets/TimeSeriesWidget/util/constants';
import { defaultBarVolumeFormula } from 'src/routes/widgets/TimeSeriesWidget/util/defaultFormulas';
import { type SagaContext } from 'src/store/types';
import { v4 as uuid } from 'uuid';

export function* createWidgetTabFromCreationInfo(creationInfo: WidgetCreationInfo) {
    switch (creationInfo.type) {
        case 'news': {
            const widget: NewsWidgetModel = {
                type: creationInfo.type,
                channelId: creationInfo.channelId ?? null,
                strategy: null,
            };

            return {
                id: uuid(),
                name: 'News',
                widget,
            };
        }
        case 'watchlist':
        case 'strategy': {
            const container: NonNullable<SagaContext['container']> = yield getContext('container');
            const queryClient = container.get('QueryClient');
            const defaultWorkspaceConfig: WorkspaceConfig = yield call(() =>
                queryClient.fetchUserQuery(getWorkspaceConfigQuery()),
            );

            const widget: StrategyWidgetModel = {
                type: creationInfo.type,
                channelId: creationInfo.channelId ?? null,
                strategies: creationInfo.strategies ? creationInfo.strategies : [],
                universe: creationInfo.type === 'strategy' ? null : defaultWorkspaceConfig.defaultWatchlistUniverseId,
                columnTemplate: null,
                filter: null,
                scrolling: false,
                displayMode: creationInfo.type === 'strategy' ? 'complete' : 'watchlist',
            };

            return {
                id: uuid(),
                name: getWidgetDisplayName(widget),
                widget,
            };
        }
        case 'if-then-builder': {
            const widget: IfThenBuilderWidgetModel = {
                type: 'if-then-builder',
                channelId: creationInfo.channelId ?? null,
                strategy: null,
                universe: null,
            };

            return {
                id: uuid(),
                name: getWidgetDisplayName(widget),
                widget,
            };
        }
        case 'search-alpha': {
            const widget: SearchAlphaWidgetModel = {
                type: 'search-alpha',
                channelId: null,
                displayingResults: false,
                displayingSearchForm: true,
                splitPercentage: 50,
                filter: null,
                universe: null,
                columnTemplate: null,
                scrolling: false,
                strategy: null,
            };

            return {
                id: uuid(),
                name: getWidgetDisplayName(widget),
                widget,
            };
        }
        case 'order-blotter': {
            const widget: OrderBlotterWidgetModel = {
                type: 'order-blotter',
                channelId: creationInfo.channelId ?? null,
                accountId: null,
                statusFilter: null,
                viewData: null,
                formattingSettings: null,
                tickerFilter: null,
            };

            return {
                id: uuid(),
                name: getWidgetDisplayName(widget),
                widget,
            };
        }
        case 'simple-order-entry':
            const widget: SimpleOrderEntryWidgetModel = {
                type: 'simple-order-entry',
                channelId: creationInfo.channelId ?? null,
                accountId: null,
                skipTradeConfirmation: false,
                symbol: null,
            };

            return {
                id: uuid(),
                name: getWidgetDisplayName(widget),
                widget,
            };

        case 'order-entry': {
            const widget: OrderEntryWidgetModel = {
                type: 'order-entry',
                channelId: creationInfo.channelId ?? null,
                accountId: null,
                skipTradeConfirmation: false,
                symbol: null,
            };

            return {
                id: uuid(),
                name: getWidgetDisplayName(widget),
                widget,
            };
        }
        case 'messages': {
            const widget: MessagesWidgetModel = {
                type: 'messages',
                channelId: creationInfo.channelId ?? null,
                accountId: null,
                statusFilter: null,
                viewData: null,
                formattingSettings: null,
            };

            return {
                id: uuid(),
                name: getWidgetDisplayName(widget),
                widget,
            };
        }
        case 'positions': {
            const widget: PositionsWidgetModel = {
                type: 'positions',
                channelId: creationInfo.channelId ?? null,
                columnTemplate: null,
                platforms: null,
                accountIds: null,
                userIds: null,
                filter: null,
            };

            return {
                id: uuid(),
                name: getWidgetDisplayName(widget),
                widget,
            };
        }
        case 'aggregated-positions': {
            const widget: AggregatedPositionsWidgetModel = {
                type: 'aggregated-positions',
                channelId: creationInfo.channelId ?? null,
                columnTemplate: null,
                platforms: null,
                accountIds: null,
                userIds: null,
                aggregateBy: 'user',
                filter: null,
            };

            return {
                id: uuid(),
                name: getWidgetDisplayName(widget),
                widget,
            };
        }
        case 'accounts': {
            const widget: AccountsWidgetModel = {
                type: 'accounts',
                channelId: creationInfo.channelId ?? null,
                columnTemplate: null,
                platforms: null,
                accountIds: null,
                userIds: null,
                filter: null,
            };

            return {
                id: uuid(),
                name: getWidgetDisplayName(widget),
                widget,
            };
        }

        case 'orders': {
            const widget: OrdersWidgetModel = {
                type: 'orders',
                channelId: creationInfo.channelId ?? null,
                columnTemplate: null,
                platforms: null,
                accountIds: null,
                userIds: null,
                filter: null,
            };

            return {
                id: uuid(),
                name: getWidgetDisplayName(widget),
                widget,
            };
        }

        case 'table': {
            const widget: TableWidgetModel = {
                type: 'table',
                channelId: creationInfo.channelId ?? null,
                table: null,
                class: null,
                filter: null,
                viewData: null,
            };
            return {
                id: uuid(),
                name: getWidgetDisplayName(widget),
                widget,
            };
        }

        case 'executions': {
            const widget: ExecutionsWidgetModel = {
                type: 'executions',
                channelId: creationInfo.channelId ?? null,
                columnTemplate: null,
                platforms: null,
                accountIds: null,
                userIds: null,
                filter: null,
            };

            return {
                id: uuid(),
                name: getWidgetDisplayName(widget),
                widget,
            };
        }

        case 'alpha-lens': {
            const container: NonNullable<SagaContext['container']> = yield getContext('container');
            const queryClient = container.get('QueryClient');
            const baseWidget: Pick<AlphaLensContent, 'symbol' | 'pages'> = yield call(() =>
                queryClient.fetchUserQuery({
                    queryKey: ['alpha-lens-widget-model-default'],
                    queryFn: getAlphaLensDefaultWidget,
                }),
            );

            const widget: AlphaLensWidgetModel = {
                type: 'alpha-lens',
                channelId: creationInfo.channelId ?? null,
                keyboardCommands: [],
                header: undefined,
                pages: null,
                symbol: baseWidget.symbol,
            };

            return {
                id: uuid(),
                name: 'Alpha Lens',
                widget,
            };
        }

        case 'locates': {
            const widget: LocatesWidgetModel = {
                type: 'locates',
                channelId: creationInfo.channelId ?? null,
                accountIds: null,
                filter: null,
                isQuantityKilo: false,
                isSoundAlertEnabled: false,
                selectedTab: 'all',
                isUserOnly: true,
                formData: {
                    accountId: null,
                    symbol: '',
                    quantity: defaultQuantityStep,
                    providerId: null,
                },
                ordersTableData: {
                    viewData: null,
                    formattingSettings: null,
                },
                costInfoTableData: {
                    columnTemplate: null,
                },
            };

            return {
                id: uuid(),
                name: getWidgetDisplayName(widget),
                widget,
            };
        }
        case 'time-series': {
            const widget: TimeSeriesWidgetModel = {
                type: 'time-series',
                barLength: { value: 1, period: PeriodType.day },
                channelId: creationInfo.channelId ?? null,
                interval: {
                    start: Duration.ofDays(365),
                    end: null,
                },
                session: 'extended',
                plotBandColors: {
                    closed: 'rgba(100,100,100,.4)',
                    premarket: 'rgba(100,0,0,.4)',
                    postmarket: 'rgba(0,0,100,.4)',
                    market: '',
                },
                symbol: creationInfo.defaultSymbol || 'SPY',
                panes: [
                    {
                        id: timeSeriesPaneNames[0],
                        symbol: null,
                        height: 80,
                        axes: [
                            {
                                id: timeSeriesAxisNames[0],
                                series: [
                                    {
                                        id: PRIMARY_SERIES_ID,
                                        symbol: undefined,
                                        type: 'candle',
                                        hidden: false,
                                        dataSource: {},
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id: timeSeriesPaneNames[1],
                        symbol: null,
                        height: 20,
                        axes: [
                            {
                                id: timeSeriesAxisNames[0],
                                series: [
                                    {
                                        id: VOLUME_SERIES_ID,
                                        symbol: undefined,
                                        type: 'column',
                                        hidden: false,
                                        // TODO AMP: Temporary until formulas are implemented, PLAT-5405
                                        dataSource: { value: defaultBarVolumeFormula },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            };

            return {
                id: uuid(),
                name: getWidgetDisplayName(widget),
                widget,
            };
        }
    }
}
